<template>
  <a-link :to="categoryUrl" class="category">
    <a-icon
      v-if="isIconVisible"
      :icon="ICON.FOLDER"
      :width="14"
      :height="11"
      class="category__icon"
    />
    <span :class="categoryNameClass">{{ categoryNameToShow }}</span>
  </a-link>
</template>

<script>
import mixins from '@/utils/mixins'
import AIcon, { ICON } from 'shared/AIcon'
import { SPECIAL_CATEGORY } from 'enums/special-categories'

export default {
  name: 'ACategory',
  mixins: [mixins.urlGenerators, mixins.ampClass],
  components: { AIcon },
  props: {
    categoryName: {
      type: String,
      required: true
    },
    categorySlug: {
      type: String,
      required: true
    },
    subcategoryName: {
      type: String,
      default: ''
    },
    subcategorySlug: {
      type: String,
      default: ''
    },
    showIconIfCategorySpecial: {
      type: Boolean,
      default: false
    },
    customName: {
      type: String,
      default: null
    }
  },
  data() {
    return { ICON }
  },
  computed: {
    isIconVisible() {
      /** Always display icon for NonSpecial categories.
       * For special categories - hidden by default,
       * but can be managed by prop "showIconIfCategorySpecial"
       * **/
      return !(this.isSpecialCategory && !this.showIconIfCategorySpecial)
    },
    isSpecialCategory() {
      return (
        this.categorySlug.toLowerCase() === SPECIAL_CATEGORY.THOUGHT_LEADERSHIP
      )
    },
    categoryNameClass() {
      return {
        category__name: true,
        category__name_special: this.isSpecialCategory,
        ...this.$_ampClass
      }
    },
    categoryNameToShow() {
      return this.customName || this.subcategoryName || this.categoryName
    },
    categoryUrl() {
      return this.generateCategoryPath({
        CategorySlug: this.categorySlug,
        SubCategorySlug: this.subcategorySlug
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  display: inline-flex;
  align-items: center;
  font-size: 0;
  min-height: 19px;
}

.category__icon {
  margin-right: 3px;
}

.category__name {
  font-weight: 400;

  $font-size: (
    $above-desktop-xl: 14px,
    $mobile: 12px
  );
  $line-height: (
    $above-desktop-xl: 19px,
    $mobile: 14px
  );

  @include setStyleByBreakpoint('font-size', $font-size, $handle-amp: true);
  @include setStyleByBreakpoint('line-height', $line-height, $handle-amp: true);
}

.category:hover {
  color: $c--gray-main;

  /deep/ .category__icon svg {
    fill: $c--main;
  }

  .category__name {
    color: $c--main;
  }
}

.category__name_special {
  color: $c--main;
}
</style>
