<template>
  <div v-if="sticker.url" class="article-sticker">
    <div class="article-sticker__img">
      <a-image
        v-if="$helper.isNotAmpPage($route.name)"
        :src="sticker.url"
        :alt="sticker.name"
        :aspect-ratio="$options.consts.aspectRatios.sticker"
      />
      <amp-img
        v-else
        :alt="sticker.name"
        :src="stickerUrl"
        layout="fixed"
        width="29"
        height="29"
      ></amp-img>
    </div>
    <span class="article-sticker__name">{{ sticker.name }}</span>
  </div>
</template>

<script>
import { aspectRatios } from 'enums/aspectRatios'
import { IMAGE_SIZE } from 'enums/images'

export default {
  name: 'ASticker',
  props: {
    sticker: {
      type: Object,
      required: true
    }
  },
  consts: { aspectRatios },
  computed: {
    stickerUrl() {
      if (!this.sticker.url) return null

      return this.$helper.replaceImageUrlSuffix({
        originalUrl: this.sticker.url,
        neededSize: IMAGE_SIZE.WIDTH_50
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.article-sticker__name {
  display: inline-block;

  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;

  @include mobile {
    font-size: 14px;
    line-height: 14px;
  }
}

.article-sticker__img {
  width: 29px;
  margin: 0 5px 5px 0;
  display: flex;

  @include mobile {
    margin: 0;
  }
}
</style>

<style lang="scss">
.amp-layout__wrapper {
  .article-sticker__name {
    font-size: 14px;
    line-height: 14px;
  }

  .article-sticker__img {
    margin: 0;
  }
}
</style>
