<template>
  <section class="homepage-top-article__wrapper">
    <div class="homepage-top-article__text-container">
      <h3 class="homepage-top-article__heading">
        <a-link :to="article.canonicalUrl">
          {{ article.title }}
        </a-link>
      </h3>
      <a-hidden-time-for-crawlers :time="article.publishedOn" />
      <div class="homepage-top-article__categories-and-timestamp">
        <a-visibility hide :on="[$breakpoint.aboveDesktopXl]">
          <div class="homepage-top-article__meta">
            <a-sticker
              v-if="article.sticker"
              key="mainArticleSticker"
              :sticker="article.sticker"
              class="homepage-top-article__sticker"
            />
            <a-category
              v-bind="categoryProps"
              class="homepage-top-article__category"
            />
            <a-tag
              :tag="article.mainTag"
              class="homepage-top-article__main-tag"
            />
          </div>
        </a-visibility>
        <a-obfuscated-display-time
          :time="article.publishedOn"
          class="homepage-top-article__timestamp"
        />
      </div>
      <a-visibility hide :on="[$breakpoint.mobile]">
        <a-tldr :tldr="article.tldr" class="homepage-top-article__tldr" />
      </a-visibility>
    </div>
    <a-media
      :image="article.featuredImage"
      :alt="article.title"
      :image-aspect-ratio="$options.consts.aspectRatios.article"
      :video="article.featuredVideo"
      :video-aspect-ratio="$options.consts.aspectRatios.article"
      :url="article.canonicalUrl"
      :viewport-optimization="true"
      :rounded="true"
      :srcset-location="$options.consts.srcsetLocation"
      class="homepage-top-article__featured-media"
    />
    <div
      class="homepage-top-article-video-banner-wrapper"
      :style="videoBannerWrapperStyles"
    >
      <a-video-banner
        :video-banner-type="$options.consts.VIDEO_BANNER_TYPE.HOMEPAGE_NEW"
        class="homepage-top-article__video-banner"
      />
    </div>
  </section>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import ASticker from 'shared/ASticker'
import ACategory from 'shared/ACategory'
import ATag from 'shared/ATag/'
import ATldr from 'shared/ATldr'
import AMedia from 'shared/AMedia'
import { aspectRatios } from 'enums/aspectRatios'
import { SRCSET_LOCATION } from 'enums/images'
import AVideoBanner from 'shared/AVideoBanner'
import { VIDEO_BANNER_TYPE } from 'enums/video-banner'
import { FIXED_LAYOUT } from 'enums/fixed-layout'
import AHiddenTimeForCrawlers from 'shared/PublishTime/AHiddenTimeForCrawlers/index.vue'
import AObfuscatedDisplayTime from 'shared/PublishTime/AObfuscatedDisplayTime/index.vue'

export default {
  name: 'HomepageTopArticle',
  components: {
    AObfuscatedDisplayTime,
    AHiddenTimeForCrawlers,
    AVideoBanner,
    AMedia,
    ATldr,
    ATag,
    ACategory,
    ASticker
  },
  mixins: [serverCacheKey],
  props: {
    article: propValidator([PROP_TYPES.OBJECT])
  },
  consts: {
    aspectRatios,
    srcsetLocation: SRCSET_LOCATION.HOMEPAGE_TOP_ARTICLE,
    VIDEO_BANNER_TYPE,
    FIXED_LAYOUT
  },
  computed: {
    categoryProps() {
      const {
        categoryName,
        categorySlug,
        subcategoryName,
        subcategorySlug
      } = this.article

      return { categoryName, categorySlug, subcategoryName, subcategorySlug }
    },
    videoBannerWrapperStyles() {
      return this.$helper.generateAspectRatioStyle(aspectRatios.article)
    }
  }
}
</script>
<style scoped lang="scss">
@import '~assets/scss/local/mixins/grid';
@import '~assets/scss/global/mixins/figures';

.mocked {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 1px solid black;
}

$gap: 20px;

.homepage-top-article__wrapper {
  $grid-setup: (
    $columnSetup: (
      $above-desktop-xl: minmax(0, 100%) 500px 500px,
      $desktop-xl: repeat(2, minmax(0, 100%)),
      $mobile: minmax(0, 100%)
    ),
    $columnGapSetup: (
      $above-desktop-xl: 20px
    ),
    $rowGapSetup: (
      $above-desktop-xl: 25px,
      $desktop-md: 15px,
      $mobile: 10px
    ),
    $combinedCellsSetup: (
      1: (
        $desktop-xl: #{1} / #{1} / #{2} / #{3},
        $mobile: auto / auto / auto / auto
      )
    )
  );

  @include gridBuilder($grid-setup);

  .homepage-top-article__heading {
    $font-size: (
      $above-desktop-xl: 30px,
      $mobile: 24px
    );
    $line-height: (
      $above-desktop-xl: 32px,
      $mobile: 28px
    );
    @include setStyleByBreakpoint('font-size', $font-size);
    @include setStyleByBreakpoint('line-height', $line-height);

    font-weight: 400;
  }

  .homepage-top-article__categories-and-timestamp {
    display: block;
    margin-top: $gap;

    @include desktop-xl {
      display: flex;
      align-items: flex-end;
    }

    @include tablet {
      margin-top: 10px;
    }

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .homepage-top-article__meta {
    display: flex;
    align-items: flex-end;
  }

  .homepage-top-article__sticker {
    margin-right: 20px;
  }

  .homepage-top-article__main-tag {
    margin-left: 15px;
  }

  .homepage-top-article__timestamp {
    display: block;
    font-size: 12px;
    line-height: 14px;
    margin-top: $gap;

    @include desktop-xl {
      margin-top: 0;
      margin-left: 40px;
    }

    @include mobile {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .homepage-top-article__tldr {
    margin-top: $gap;
  }

  $tldr-line-height: 27px;
  $bullet-size: 6px;

  /deep/ .homepage-top-article__tldr.tldr .tldr__item {
    font-size: 18px;
    line-height: $tldr-line-height;
    font-weight: 400;
    margin-bottom: 0;

    @include bullet($line-height: $tldr-line-height);
  }

  .homepage-top-article-video-banner-wrapper {
    width: 100%;
  }

  $article-aspect-ratio: 1.8;

  /deep/ #primis-wrapper .primis-player-wrapper {
    border-radius: 5px;
    overflow: hidden;

    @include generateAspectRatioStyles($article-aspect-ratio);
  }
}
</style>
