<template>
  <time :datetime="dateTime" class="hidden">
    {{ dateTime }}
  </time>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'

/**
 * The purpose of this component is to render a datetime for
 * crawlers in the most straightforward way possible
 * (showing date in ISO format for both attribute and display),
 * but hide it with CSS.
 *
 * This component is one of the steps required to fix the following issue:
 * https://adraba.atlassian.net/browse/FMP-13576
 */
export default {
  name: 'AHiddenTimeForCrawlers',
  props: {
    time: propValidator([PROP_TYPES.STRING])
  },
  computed: {
    dateTime() {
      return this.$helper.formatDate.toOffset(this.time)
    }
  }
}
</script>
