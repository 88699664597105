export const VIDEO_BANNER_TYPE = {
  HOMEPAGE: 'HOMEPAGE',
  HOMEPAGE_NEW: 'HOMEPAGE_NEW',
  ALL_NEWS: 'ALL_NEWS',
  CATEGORY: 'CATEGORY',
  SUBCATEGORY: 'SUBCATEGORY',
  ARTICLE: 'ARTICLE'
}

export const VIDEO_BANNER_SETTINGS_BY_BANNER_TYPE = {
  [VIDEO_BANNER_TYPE.HOMEPAGE]: {
    playerApiId: '1',
    playerIdDesktop: null,
    playerIdMobile: '105993'
  },
  [VIDEO_BANNER_TYPE.HOMEPAGE_NEW]: {
    playerApiId: '6',
    playerIdDesktop: '116057',
    playerIdMobile: '116057'
  },
  [VIDEO_BANNER_TYPE.ALL_NEWS]: {
    playerApiId: '2',
    playerIdDesktop: '105994',
    playerIdMobile: '105995'
  },
  [VIDEO_BANNER_TYPE.CATEGORY]: {
    playerApiId: '3',
    playerIdDesktop: '105994',
    playerIdMobile: '105995'
  },
  [VIDEO_BANNER_TYPE.SUBCATEGORY]: {
    playerApiId: '4',
    playerIdDesktop: '105994',
    playerIdMobile: '105995'
  },
  [VIDEO_BANNER_TYPE.ARTICLE]: {
    playerApiId: '5',
    playerIdDesktop: '105991',
    playerIdMobile: '105992'
  }
}
