<template>
  <ul v-if="TLDRArray.length" class="tldr text-body">
    <li
      class="text-body tldr__item"
      :class="dynamicClasses"
      v-for="(tldr, i) in TLDRArray"
      :key="`${tldr}${i}`"
    >
      {{ tldr }}
    </li>
  </ul>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import mixins from '@/utils/mixins'

export default {
  name: 'ATldr',
  mixins: [mixins.ampClass],
  props: {
    tldr: propValidator([PROP_TYPES.STRING]),
    fontBold: propValidator([PROP_TYPES.BOOLEAN], false, true),
    itemsToShow: propValidator([PROP_TYPES.NUMBER], false)
  },
  data() {
    return {}
  },
  computed: {
    TLDRArray() {
      try {
        const tldr = JSON.parse(this.tldr)
        if (!Array.isArray(tldr)) return []

        const tldrWithoutEmptyItems = tldr.filter(v => !!v)

        return this.itemsToShow
          ? tldrWithoutEmptyItems.slice(0, this.itemsToShow)
          : tldrWithoutEmptyItems
      } catch (err) {
        this.$errorHandler(err, this, { showMessage: false })
        return []
      }
    },
    dynamicClasses() {
      return [{ bold: this.fontBold }, this.$_ampClass]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/global/mixins/figures';

.tldr {
  display: inline-block;

  .tldr__item {
    list-style-type: none;
    margin-left: 15px;
    margin-bottom: 5px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    @include bullet($line-height: 22px);
  }
}
</style>
