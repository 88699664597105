<template>
  <span v-html="formattedTimestamp" />
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'

/**
 * The idea is to "obfuscate" the HTML of the date to prevent Google
 * from parsing it, which may result in incorrect publish time in
 * the search results
 *
 * This component is one of the steps required to fix the following issue:
 * https://adraba.atlassian.net/browse/FMP-13576
 */
export default {
  name: 'AObfuscatedDisplayTime',
  props: {
    time: propValidator([PROP_TYPES.STRING])
  },
  computed: {
    formattedTimestamp() {
      const timestamp = this.$helper.formatDate.toDefault(this.time)

      return timestamp
        .split('')
        .map(char => `<span>${char}</span>`)
        .join('')
    }
  }
}
</script>
