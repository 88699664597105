<template>
  <a-link v-if="tag" class="tag" :to="generateTagPath(tag)">
    <a-icon :icon="ICON.LABEL" :width="24" :height="12" class="tag__icon" />
    <span :class="$_ampClass" class="tag__name">{{ tagName }}</span>
  </a-link>
</template>

<script>
import { prop } from 'ramda'

import { PROP_TYPES, propValidator } from '@/utils/validators'
import mixins from '@/utils/mixins'
import AIcon, { ICON } from 'shared/AIcon'

export default {
  name: 'ATag',
  mixins: [mixins.urlGenerators, mixins.ampClass],
  components: { AIcon },
  props: {
    tag: propValidator([PROP_TYPES.OBJECT])
  },
  data() {
    return { ICON }
  },
  computed: {
    tagName() {
      return this.$helper.processResponse(this.tag)?.name
    }
  },
  methods: { prop }
}
</script>

<style lang="scss" scoped>
.tag {
  display: flex;
  align-items: center;
  font-size: 0;
  margin-right: 10px;
}

.tag__icon {
  width: 24px;
  height: 12px;
  margin-right: 3px;
}

.tag__name {
  $font-size: (
    $above-desktop-xl: 14px,
    $mobile: 12px
  );
  $line-height: (
    $above-desktop-xl: 19px,
    $mobile: 14px
  );
  font-weight: 400;

  @include setStyleByBreakpoint('font-size', $font-size, $handle-amp: true);
  @include setStyleByBreakpoint('line-height', $line-height, $handle-amp: true);
}

.tag:hover {
  color: $c--gray-main;

  /deep/ .tag__icon svg {
    fill: $c--main;
  }

  .tag__name {
    color: $c--main;
  }
}
</style>
